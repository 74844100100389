import React, { useEffect, useState } from 'react';
import {
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress } from '@material-ui/core';
import {
  FormContainer,
  StyledTextField,
} from './FormStyles';
import { useUser } from '../../contexts/UserContext';
import { emailRegex } from '../../utils/Regex';
import { getDetails, updateProfile } from '../../api/api';
import NotificationToast from '../notifications/NotificationToast';
import i18n from '../../i18n/i18n';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

type Error = {
  email: string | null,
}

const ProfileForm = ({ history }) => {
  const { user, setUser } = useUser();

  const [name, setName] = React.useState<string | undefined>('');
  const [email, setEmail] = React.useState<string | undefined>('');
  const [mobilePhone, setMobilePhone] = React.useState<string | null>('');
  const [changed, setChanged] = React.useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notification, setNotification] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [error, setError] = React.useState<Error>({
    email: null,
  });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateProfile(user, name, mobilePhone);
      history.push('home');
    } catch (err) {
      setNotification({
        severity: 'error',
        title: i18n.t('error'),
        message: i18n.t('error_occured_try_later'),
      });
      setShowNotification(true);
    }
  };

  const getCustomerInfo = async () => {
    if (user) {
      try {
        const info = await getDetails(user);
        setUser({
          ...user,
          user: {
            name: info.name,
            email: info.email,
            mobilePhone: info.mobilePhone,
          },
        });
        setName(info.name);
        setEmail(info.email);
        setMobilePhone(info.mobilePhone);
        setLoading(false);
      } catch (err) {
        setNotification({
          severity: 'error',
          title: i18n.t('error'),
          message: i18n.t('error_occured_try_later'),
        });
        setShowNotification(true);
      }
    }
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  useEffect(() => {
    if (!email?.length) {
    } else if (email && !emailRegex.test(email)) {
      setError((prevState) => ({ ...prevState, email: i18n.t('invalid_email') }));
    } else {
      setError((prevState) => ({ ...prevState, email: null }));
    }
  }, [email, name, mobilePhone]);

  useEffect(() => {
    if (email !== user?.user?.email || name !== user?.user?.name || mobilePhone !== user?.user?.mobilePhone) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [mobilePhone, name, email, setChanged, user]);

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="50%"
    >
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <FormContainer
      style={{
        margin: '0 2vh 2vh 2vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        height: '100%',
      }}
    >
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <form style={{ width: '100%' }} noValidate autoComplete="off" onSubmit={onSubmit} id="profile">
        <StyledDiv>
          <span>{i18n.t('name')}</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setName(e.target.value)}
            id="name"
            value={name}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('Email')}</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            error={!!error?.email}
            id="email"
            disabled
            value={email}
            helperText={error.email ? error.email : ''}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('mobile_phone')}</span>
          <StyledTextField
            fullWidth
            onChange={(e) => setMobilePhone(e.target.value)}
            id="mobilePhone"
            value={mobilePhone}
          />
        </StyledDiv>
        <ButtonsContainer>
          <StyledButton
            variant="outlined"
            color="secondary"
            type="submit"
            form="profile"
            disabled={!changed || error.email}
          >
            {i18n.t('save')}
          </StyledButton>
        </ButtonsContainer>
      </form>
      <Spacer />
    </FormContainer>
  );
};

export default withRouter(ProfileForm);

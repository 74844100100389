import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import ReactPDF from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Card from '@mui/material/Card';
import EvStationIcon from '@mui/icons-material/EvStation';
import TimerIcon from '@mui/icons-material/Timer';
import EuroIcon from '@mui/icons-material/Euro';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { DocumentInfo, SessionInfo } from '../types/types';
import server, { closeSession, getDocuments } from '../api/api';
import PdfBuilder from './PdfBuilder';
import i18n from '../i18n/i18n';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DocumentsContainer = styled.div`
  overflow-y: scroll;
  align-self: center;
  width: 100%;
  margin-top: 10px;
`;

const HeaderName = styled.h1`
  font: 1.6em Poppins;
  color: #181818;
  width: 100%;
  text-align: center;
`;

const StyledSpanPin = styled.span`
  font: 1.2em Poppins;
  color: black
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: #f0b239;
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 300px;
    text-transform: capitalize;
    font: 0.85em Poppins;
  }
`;

const StyledButton2 = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: #f0b239;
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 25%;
  height: 100px;
  padding: 10px
`;

const StyledSpan = styled.span`
  margin-top: 20px;
  font-weight: bold;
`;

const StyledP = styled.p`
  margin-top: 1px;
`;

const CustomerDocumentsView = ({ location }) => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
  const [loadingTerminate, setLoadingTerminate] = useState(false);

  const sessionId = location.pathname.split('/')[3];

  const fetchDocuments = async (id: string) => {
    try {
      const response = await getDocuments(id);
      setSessionInfo(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSessionUpdate = (newInfo) => {
    setSessionInfo((prevState : any) => ({
      ...prevState,
      state: newInfo.sessionState,
      extra: {
        ...prevState.extra,
        ...(newInfo.update && { chargingData: newInfo.update }),
      },
    }));

    if (newInfo.state === 300) {
      setLoadingTerminate(false);
    }
  };

  useEffect(() => {
    fetchDocuments(sessionId);
    server.onSocket('session_update', handleSessionUpdate);
    return () => {
      server.offSocket('session_update', handleSessionUpdate);
    };
  }, []);

  const chargingData = sessionInfo?.extra?.chargingData;
  const price = (chargingData?.kWh * (chargingData?.priceKwh / 100)).toString();
  const time = moment.duration(chargingData?.chargingTime, 'seconds');

  return (
    <Root>
      {sessionInfo && (
        <HeaderName>
          {sessionInfo?.vendingMachine?.identificationNumber}
          {' '}
          |
          {' '}
          {sessionInfo?.vendingMachine?.name}
        </HeaderName>
      )}
      <StyledSpanPin>
        PIN:
        {sessionInfo?.pin}
      </StyledSpanPin>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          size={200}
          sx={{
            marginTop: '10px',
            marginBottom: '30px',
            color: '#f0b239',
            animationDuration: '80000ms',
          }}
          thickness={1}
          variant={sessionInfo?.state === 100 ? 'indeterminate' : 'determinate'}
          value={sessionInfo?.state === 100 ? undefined : 100}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{ color: 'black', textAlign: 'center' }}
          >
            {sessionInfo?.state === 100 ? i18n.t('charging') : i18n.t('charging_complete')}
          </span>
          {sessionInfo?.state === 100
            && (
              <ButtonsContainer>
                <StyledButton2
                  variant="outlined"
                  color="secondary"
                  disabled={loadingTerminate}
                  onClick={async () => {
                    setLoadingTerminate(true);
                    await closeSession(sessionInfo?.sessionId);
                  }}
                >
                  {loadingTerminate
                    && (
                      <CircularProgress
                        style={{ marginRight: '10px' }}
                        size={20}
                        color="secondary"
                        variant="indeterminate"
                      />
                    )}
                  Terminar
                </StyledButton2>
              </ButtonsContainer>
            )}
        </Box>
      </Box>
      <div style={{ width: 'calc(100% - 24px)', marginBottom: '30px' }}>
        <Row>
          <StyledCard style={{ backgroundColor: '#DCDCDC' }}>
            <EvStationIcon fontSize="large" sx={{ color: '#808080' }} />
            <StyledSpan>kWh:</StyledSpan>
            <StyledP>{chargingData ? chargingData?.kWh : '---'}</StyledP>
          </StyledCard>
          <StyledCard
            style={{
              marginLeft: '4%',
              marginRight: '4%',
              backgroundColor: '#DCDCDC',
            }}
          >
            <TimerIcon fontSize="large" sx={{ color: '#808080' }} />
            <StyledSpan>{i18n.t('duration')}</StyledSpan>
            <StyledP>{chargingData ? moment.utc(time.asMilliseconds()).format('HH:mm:ss') : '---'}</StyledP>
          </StyledCard>
          <StyledCard style={{ backgroundColor: '#DCDCDC' }}>
            <EuroIcon fontSize="large" sx={{ color: '#808080' }} />
            <StyledSpan>{i18n.t('price')}</StyledSpan>
            <StyledP>
              {chargingData ? `${parseFloat(price).toFixed(2)}€` : '---'}
            </StyledP>
          </StyledCard>
        </Row>
      </div>
      <DocumentsContainer>
        {sessionInfo && sessionInfo.documents.map((documentInfo: DocumentInfo) => (
          <ButtonsContainer>
            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={async () => {
                const pdfDocument = await ReactPDF.pdf(<PdfBuilder document={documentInfo} />).toBlob();
                const url = window.URL.createObjectURL(pdfDocument);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', i18n.t('document'));
                link.click();
                link.remove();
              }}
            >
              <DownloadIcon style={{ color: 'white', margin: '0 10px 0 0' }} />
              {documentInfo.type === 'CUSTOMER' ? i18n.t('payment_document') : i18n.t('invoice')}
            </StyledButton>
          </ButtonsContainer>
        ))}
      </DocumentsContainer>
    </Root>
  );
};

export default CustomerDocumentsView;

import React, { useEffect, useState } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { MdChevronRight } from 'react-icons/all';
import { useHistory } from 'react-router';
import * as qs from 'qs';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@mui/material/Alert';
import { CheckCircle, Cancel } from '@mui/icons-material';
import FloatingActionButton from '../components/FloatingActionButton';
import Header from '../components/TopHeader';
import { useSession } from '../contexts/SessionContext';
import BackButton from '../components/BackButton';
import BackgroundImage from '../components/BackgroundImage';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { getDetails, getMandates, requestMandate } from '../api/api';
import { useUser } from '../contexts/UserContext';
import { StyledTextField, TextFieldContainer } from '../components/forms/FormStyles';
import i18n from '../i18n/i18n';
import Button from "@material-ui/core/Button";

const StyledDiv = styled.div`
    margin: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-radius: 10px;
    width: 60%;
    height: 45px;
    text-transform: capitalize;
    font: 0.95em Poppins;
    right: 15px;
    bottom: 15px;
    position: fixed;
    margin: 0;
    padding: 0;
    border: 0;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-radius: 10px;
    width: 60%;
    height: 45px;
    text-transform: capitalize;
    font: 0.95em Poppins;
    right: 15px;
    bottom: 15px;
    position: fixed;
    margin: 0;
    padding: 0;
    border: 0;
  `
}
`;

const StyledCheckbox = styled(({ ...rest }) => (
  <Checkbox
    {...rest}
  />
))`
  && {
  ${({ checked }) => checked && `
     color: green !important;
  `
}
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

export default ({ location }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mandate, setMandate] = useState<any>(undefined);
  const [acceptedMandate, setAcceptedMandate] = useState<boolean>(true);
  const phoneNumberFormatter = PhoneNumber.getAsYouType('PT');
  const telephoneNumber = new PhoneNumber(phoneNumber || '', 'PT');

  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { vendingMachineInfo } = useVendingMachine();
  const { selectPaymentMethod } = useSession();
  const history = useHistory();
  const { user } = useUser();

  const { paymentMethod } = history?.location?.state || {};

  const onSubmit = async (mobilePhone) => {
    if (!mandate) {
      const response = await requestMandate(
        vendingMachineInfo?.id,
        undefined,
        paymentMethod.gatewayName,
        telephoneNumber.getNumber().replace('+', ''),
      );

      if (response.state === 'PENDING') {
        history.push('/mandate', { mobilePhone, mandateId: response.socketId });
      }
    } else {
      selectPaymentMethod({
        gatewayName: 'SPG',
        gatewayParameters: {
          mobilePhone,
        },
      });
      history.push('/product-selection');
    }
  };

  useEffect(() => {
    if (!vendingMachineInfo && !s) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s) {
      history.push(`/load?s=${s}`);
    }

    (async () => {
      const userInfo = await getDetails(user);

      phoneNumberFormatter.reset(userInfo?.mobilePhone);
      setPhoneNumber(phoneNumberFormatter.getPhoneNumber().getNumber('national'));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (telephoneNumber.isMobile()) {
        const mandateDetails = await getMandates('SPG', vendingMachineInfo?.id);

        const findMandate = mandateDetails.filter((elem) => elem.alias.replace('351#', '') === phoneNumber
          .replaceAll(' ', ''));

        setMandate(findMandate[0]);
      }
    })();
  }, [phoneNumber]);

  const getAlert = (error: string) => (
    <Alert
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'start',
        width: '80%',
        marginTop: '20px',
      }}
      severity="error"
    >
      {i18n.t(error)}
    </Alert>
  );

  return (
    <BackgroundImage equipmentTypeId={vendingMachineInfo?.equipmentType?.id}>
      <Header showVm userLogo />
      <StyledDiv>
        <span>{i18n.t('mobile_phone')}</span>
        <StyledTextField
          fullWidth
          onChange={(e) => {
            phoneNumberFormatter.reset(e.target.value);
            setPhoneNumber(phoneNumberFormatter.getPhoneNumber().getNumber('national') || e.target.value);
          }}
          id="mobilePhone"
          value={phoneNumber}
          placeholder="999 999 999"
        />
        {mandate && mandate.isActive
          && (
            <div style={{ marginTop: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'start',
                  padding: '10px',
                }}
              >
                <CheckCircle style={{ color: 'green' }} />
                <div>
                  <b>{i18n.t('authorized')}</b>
                  <br />
                  <br />
                  <small>{i18n.t('can_change_settings_in_mbway')}</small>
                </div>
              </div>
            </div>
          )}
        {mandate && !mandate.isActive && (
          <div style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'start',
                padding: '10px',
              }}
            >
              <Cancel style={{ color: 'red', marginRight: '10px' }} />
              <div>
                <b>{i18n.t('suspended')}</b>
                <br />
                <br />
                <small>{i18n.t('can_change_settings_in_mbway')}</small>
              </div>
            </div>
          </div>
        )}
        {!mandate
          && (
            <div style={{ backgroundColor: '#f8f8f8', marginTop: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'start',
                  padding: '10px',
                }}
              >
                <StyledCheckbox
                  checked={acceptedMandate}
                  onChange={(event) => {
                    setAcceptedMandate(event.currentTarget.checked);
                  }}
                />
                <div>
                  <b>{i18n.t('accept_add_authorized_payments')}</b>
                  <br />
                  <br />
                  <small>{i18n.t('dont_need_to_validate_next_purchases')}</small>
                </div>
              </div>
            </div>
          )}
        {!acceptedMandate && getAlert('need_accept_authorized_payments')}
        {mandate && !mandate.isActive && getAlert('need_change_authorized_payments')}
      </StyledDiv>
      <Spacer />
      <BackButton screen={`/home${s ? `?s=${s}` : ''}`} />
      <StyledButton
        variant="outlined"
        color="secondary"
        type="button"
        disabled={!(telephoneNumber.isMobile() && (mandate ? mandate.isActive : acceptedMandate))}
        onClick={() => onSubmit(telephoneNumber.getNumber().replace('+', ''))}
      >
        {i18n.t('Autorizar pagamento')}
        <MdChevronRight style={{ fontSize: '45px', right:0, position: 'absolute' }} />
      </StyledButton>
    </BackgroundImage>
  );
};

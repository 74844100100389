import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import snacks from '../images/icon_snacks.svg';
import server from '../api/api';

export const IconContext = createContext({});

export const IconContextProvider = ({ children }) => {
  const [icons, setIcons] = useState({});
  const [loading, setLoading] = useState(true);

  async function loadIcons() {
    const response = await server.fetch('customer/vendingMachines/equipmentTypes', {
      method: 'GET',
    });

    const responseJson = await response.json();

    if (response.status !== 200 || !responseJson || !Array.isArray(responseJson)) {
      console.log('Error fetching equipment types');
      return;
    }

    responseJson.forEach((equipmentType) => {
      setIcons((prev) => ({
        ...prev,
        [equipmentType.id]: {
          label: equipmentType?.label,
          icon: <img
            alt=""
            width="30px"
            height="30px"
            src={equipmentType.assetUrl}
          />,
        },
      }));
    });

    setLoading(false);
  }

  function getEquipmentTypes() {
    return Object.keys(icons).map((equipmentTypeId) => {
      const equipmentTypeInfo = icons[equipmentTypeId];

      return {
        id: equipmentTypeId,
        label: equipmentTypeInfo.label,
        icon: equipmentTypeInfo.icon,
      };
    });
  }

  function getEquipmentType(equipmentTypeId) {
    const equipmentType = icons[equipmentTypeId];

    if (equipmentType) {
      return {
        id: equipmentTypeId,
        label: equipmentType.label,
        icon: equipmentType.icon,
      };
    }

    return null;
  }

  function getDefaultIcon() {
    return <img width="30px" height="30px" alt="" src={snacks} />;
  }

  function getIcon(equipmentTypeId) {
    if (!equipmentTypeId) {
      return null;
    }

    if (!icons[equipmentTypeId]) {
      return getDefaultIcon();
    }

    return icons[equipmentTypeId].icon || getDefaultIcon();
  }

  useEffect(() => {
    loadIcons();
  }, []);

  const value = useMemo(() => ({
    getIcon,
    getEquipmentTypes,
    getEquipmentType,
    loadingIcons: loading,
  }), [getIcon, getEquipmentTypes, getEquipmentType, loading]);

  return (
    <IconContext.Provider value={value}>
      {children}
    </IconContext.Provider>
  );
};

export const useIconContext = () => useContext(IconContext);

import React, { useEffect, useState } from 'react';
import {
  RouteProps,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import zxcvbn from 'zxcvbn';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Box, CircularProgress } from '@material-ui/core';
import {
  FormContainer,
  TextFieldContainer,
  StyledTextField,
} from './FormStyles';
import { changePassword } from '../../api/api';
import { useUser } from '../../contexts/UserContext';
import ErrorMessage from '../ErrorMessage';
import { passwordStrengthColors, passwordStrengthLabels } from '../../constants/User';
import NotificationToast from '../notifications/NotificationToast';
import i18n from '../../i18n/i18n';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex:1
`;

const getPasswordStrength = (text?: string | null) => {
  const score = Math.min(zxcvbn(text).score, 4);
  return {
    score,
    message: i18n.t(passwordStrengthLabels[score]),
    color: passwordStrengthColors[score],
  };
};

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const StyledDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

type Error = {
  currentPasswordError: string | null,
  newPasswordError: string | null,
  confirmNewPasswordError: string | null,
  changePasswordError: string | null
}

const ChangePwForm = () => {
  const { user, authenticateNewToken } = useUser();
  const [currentPassword, setCurrentPassword] = React.useState<string | null>(null);
  const [newPassword, setNewPassword] = React.useState<string | null>(null);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState<string | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notification, setNotification] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = React.useState<Error>({
    currentPasswordError: null,
    newPasswordError: null,
    confirmNewPasswordError: null,
    changePasswordError: null,
  });

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefauli18n.i18n.t();

    try {
      const response = await changePassword(user, currentPassword, newPassword);
      setError((prevState) => ({ ...prevState, changePasswordError: null }));
      await authenticateNewToken(response.token);
      setLoading(false);
      setNotification({
        severity: 'success',
        title: i18n.t('success'),
        message: i18n.t('password_changed_successfully'),
      });
      setNewPassword(null);
      setConfirmNewPassword(null);
      setShowNotification(true);
    } catch (ex) {
      setNotification({
        severity: 'error',
        title: i18n.t('error'),
        message: i18n.t('error_occured_try_later'),
      });
      setShowNotification(true);
    }
  };

  useEffect(() => {
    const newErrors = { ...error };

    if (!currentPassword || currentPassword === '') {
      newErrors.currentPasswordError = i18n.t('mandatory');
    } else {
      newErrors.currentPasswordError = null;
    }

    if (getPasswordStrength(newPassword || '').score < 4) {
      newErrors.newPasswordError = i18n.t('password_too_simple');
    } else {
      newErrors.newPasswordError = null;
    }

    if (!newPassword || newPassword === '') {
      newErrors.newPasswordError = i18n.t('mandatory');
    } else if (newErrors.newPasswordError === null) {
      newErrors.newPasswordError = null;
    }

    if (!confirmNewPassword || confirmNewPassword === '') {
      newErrors.confirmNewPasswordError = i18n.t('mandatory');
    } else {
      newErrors.confirmNewPasswordError = null;
    }

    if (confirmNewPassword && newPassword !== confirmNewPassword) {
      newErrors.confirmNewPasswordError = i18n.t('new_different_password');
    } else if (newErrors.confirmNewPasswordError !== i18n.t('mandatory')) newErrors.confirmNewPasswordError = null;

    setError(newErrors);
  }, [currentPassword, newPassword, confirmNewPassword]);

  let passwordStrength;
  if (newPassword) {
    passwordStrength = getPasswordStrength(newPassword);
  }

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="50%"
    >
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <FormContainer
      style={{
        margin: '0 2vh 2vh 2vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        height: '100%',
      }}
    >
      <NotificationToast
        open={showNotification}
        message={notification && notification.message}
        title={notification && notification.title}
        severity={notification && notification.severity}
        onClose={() => setShowNotification(false)}
      />
      <form style={{ width: '100%' }} noValidate autoComplete="off" onSubmit={onSubmit} id="changepw">
        <StyledDiv>
          <span>{i18n.t('current_password')}</span>
          <StyledTextField
            fullWidth
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            error={!!error.currentPasswordError}
            id="currentPassword"
            helperText={error.currentPasswordError ? error.currentPasswordError : ''}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('new_password')}</span>
          <StyledTextField
            fullWidth
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!error.newPasswordError}
            id="newPassword"
            helperText={(
              <>
                {(
                  newPassword && (
                    <LinearProgress
                      style={{ backgroundColor: passwordStrength.color }}
                      value={100 - (passwordStrength.score * 25)}
                      variant="determinate"
                    />
                  )
                )}
                {error.newPasswordError ? error.newPasswordError : ' '}
              </>
            )}
            InputProps={{
              endAdornment: newPassword ? (
                <InputAdornment position="end">
                  <span style={{ color: passwordStrength.color }}>
                    {passwordStrength.message}
                  </span>
                </InputAdornment>
              ) : null,
            }}
          />
        </StyledDiv>
        <StyledDiv>
          <span>{i18n.t('confirm_password')}</span>
          <StyledTextField
            fullWidth
            type="password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            error={!!error.confirmNewPasswordError}
            id="confirmNewPassword"
            helperText={error.confirmNewPasswordError ? error.confirmNewPasswordError : ''}
          />
        </StyledDiv>
        <ButtonsContainer>
          <StyledButton
            variant="outlined"
            color="secondary"
            type="submit"
            form="changepw"
            disabled={
                error.currentPasswordError
                  || error.newPasswordError
                  || error.confirmNewPasswordError
                  || error.changePasswordError
            }
          >
            {i18n.t('save')}
          </StyledButton>
        </ButtonsContainer>
        {error.changePasswordError && (<ErrorMessage error={error.changePasswordError} />)}
      </form>
      <Spacer />
    </FormContainer>
  );
};

export default withRouter(ChangePwForm);

import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { formatValue } from 'react-currency-input-field';
import TopHeader from '../components/TopHeader';
import i18n from '../i18n/i18n';
import { getCustomerTransactionsHistory, getCustomerWallets } from '../api/api';
import { useUser } from '../contexts/UserContext';
import PrivateCardSlider from '../components/private-card-slider';
import ActionButtons from '../components/ActionButtons';
import { getDate, getTime } from '../utils/DateFormatter';
import getImageFromGateway from '../utils/GatewayImages';
import Accordion from '../components/accordion';
import AccordionItem from '../components/accordion-item';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
`;

const ListFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  width: 100%;
`;

const FilteredList = styled.div`
  display: flex;
  padding-top: 4px;
  align-content: start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 50vh;
`;

const ListContainer = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-direction: column;

`;

const FilterButton = styled.div<{ selected?: boolean }>`
  flex: 1;
  padding: 2%;
  background: ${(props) => (props.selected ? 'rgba(99,100,103,255)' : 'rgb(241,241,241)')};
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
  color: ${(props) => (props.selected ? 'rgb(255,255,255)' : 'rgb(161,161,161)')};
  font: 1em Poppins;
`;

const Spacer = styled.div`
  min-width: 10px;
`;

const Label = styled.span`
  font-size: 0.85em;
  min-width: 110px;
  display: inline-block;
`;

const BoldLabel = styled.span`
  font-size: 0.85em;
  font-weight: bold;
  min-width: 110px;
  display: inline-block;
  width: 40%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const NoVMView = ({ history }) => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [loading] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const { user } = useUser();
  const [paymentMethod, setPaymentMethod] = useState<any>(undefined);
  const [customerWallets, setCustomerWallets] = useState([]);
  const [tab, setTab] = React.useState('ALL');
  const [error, setError] = useState<boolean>(false);
  const backRef = useRef<HTMLDivElement>(null);

  const currentLanguage = useMemo(
    () => `${i18n.language[0]}${i18n.language[1]}-${i18n.language[2]}${i18n.language[3]}`,
    [i18n.language],
  );

  const handleListChange = (newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    setLoadingHistory(true);

    (async () => {
      try {
        if (paymentMethod?.wallet) {
          const transactions = await getCustomerTransactionsHistory(undefined, paymentMethod?.wallet?.id, tab);
          setDataSource(transactions);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoadingHistory(false);
      }
    })();
  }, [paymentMethod, tab]);

  useEffect(() => {
    (async () => {
      try {
        const finalPaymentMethods: any = [];
        const wallets = await getCustomerWallets(user);

        wallets.forEach((tokenWallet) => {
          finalPaymentMethods.push({
            gatewayName: 'TOKEN',
            wallet: tokenWallet,
          });
        });

        setPaymentMethod(finalPaymentMethods?.[0]);
        setCustomerWallets(finalPaymentMethods);
      } catch (err) {
        setShowNotification(true);
      }
    })();
  }, []);

  let message;

  switch (tab) {
    case 'ALL':
      message = 'no_purchases_registered';
      break;
    case 'PURCHASES':
      message = 'no_purchases_registered';
      break;
    case 'REVALUES':
      message = 'no_revalues_registered';
      break;
    default:
      message = '';
      break;
  }

  // @ts-ignore
  const backgroundHeight = backRef !== null ? (backRef?.current?.clientHeight + 40) || '57%' : '57%';

  return (
    <Root>
      <div style={{
        background: 'linear-gradient(90deg, rgb(249,226,153) 0%, rgb(255,147,0) 100%)',
        position: 'absolute',
        width: '100%',
        height: backgroundHeight,
        zIndex: 0,
      }}
      />
      <div
        ref={backRef}
        style={{
          flex: 1,
        }}
      >
        <TopHeader showVm={false} userLogo color="white" style={{ paddingLeft: '1em' }} />
        <PrivateCardSlider
          paymentMethods={customerWallets}
          loading={loading}
          showNotification={showNotification}
          onWalletChange={(selectedWallet) => setPaymentMethod(selectedWallet)}
          isInVendingMachine={false}
          color="rgba(255,178,44,255)"
        />
        <ActionButtons
          history={history}
          paymentMethod={paymentMethod}
          menu="initialMenu"
        />
      </div>
      <div style={{
        flex: '6 1 1px',
        background: 'rgb(217 217 217)',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '14px',
      }}
      >
        <ListContainer>
          <div style={{
            height: '100%',
            width: '92%',
            overflow: 'hidden',
            paddingTop: '2px',
            borderRadius: '12px 12px 0 0',
            background: 'white',
            zIndex: 1,
          }}
          >
            {!error && (
              <ListFiltersContainer>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '96%',
                  paddingBottom: '10px',
                  fontSize: '0.85em',
                  paddingRight: '16px',
                  paddingLeft: '16px',
                }}
                >
                  <FilterButton
                    selected={(tab === 'ALL')}
                    onClick={() => {
                      handleListChange('ALL');
                    }}
                  >
                    {i18n.t('all')}
                  </FilterButton>
                  <Spacer />
                  <FilterButton
                    selected={(tab === 'PURCHASES')}
                    onClick={() => {
                      handleListChange('PURCHASES');
                    }}
                  >
                    {i18n.t('movements')}
                  </FilterButton>
                  <Spacer />
                  <FilterButton
                    selected={(tab === 'REVALUES')}
                    onClick={() => {
                      handleListChange('REVALUES');
                    }}
                  >
                    {i18n.t('loads')}
                  </FilterButton>
                </div>
              </ListFiltersContainer>
            )}
            <FilteredList>
              {error && !loadingHistory && (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <h2 style={{ textAlign: 'center', color: 'rgba(99,100,103,255)' }}>{i18n.t('error_ocurred')}</h2>
                    <h2 style={{ textAlign: 'center', color: 'rgba(99,100,103,255)' }}>{i18n.t('try_again_later')}</h2>
                  </Box>
                </>
              )}
              {loadingHistory && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  marginTop="25%"
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}
              {!error && dataSource.length === 0 && !loadingHistory && (
                <h2 style={{
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '40%',
                  fontSize: '1.1em',
                  color: 'rgba(99,100,103,255)',
                }}
                >
                  {i18n.t(message)}
                </h2>
              )}
              {!error && dataSource.length > 0 && !loadingHistory && (
                <Accordion
                  key={34593384}
                >
                  {dataSource.map((p: any) => {
                    const revalueAmount = p?.currency === 'XXX'
                      ? p.amount
                      : (p.amount / 100)
                        .toFixed(2);

                    const notExpandable = (!p.productName && !p.vendingMachineName && !p.merchantName);

                    return (
                      <AccordionItem
                        notExpandable={notExpandable}
                        chevronMarginRight="10px"
                        highlightColor="rgba(99,100,103,255)"
                        header={(
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: notExpandable ? 'calc(100% - 34px)' : '100% !important',
                              maxWidth: notExpandable ? 'calc(100% - 34px)' : '100% !important',
                              flex: 1,
                              height: '100%',
                            }}
                          >
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '40%',
                              justifyContent: 'start',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              <StyledSpan>{getDate(p.date)}</StyledSpan>
                              <StyledSpan>{getTime(p.date)}</StyledSpan>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '35%',
                              justifyContent: 'center',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              <StyledSpan>
                                {p?.currency === 'XXX'
                                  ? `${revalueAmount} ${i18n.t('services')}`
                                  : formatValue({
                                    value: revalueAmount,
                                    groupSeparator: ',',
                                    decimalSeparator: '.',
                                    decimalScale: 2,
                                    intlConfig: { locale: currentLanguage, currency: p?.currency || 'EUR' },
                                  })}
                              </StyledSpan>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '25%',
                              justifyContent: 'center',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              {getImageFromGateway(p.gateway?.name)}
                            </div>
                          </div>
                        )}
                      >
                        <div style={{ width: 'calc(100% - 24px)' }}>
                          <Row>
                            <BoldLabel>{i18n.t('product')}</BoldLabel>
                            <Label style={{
                              display: 'inline-block',
                              maxWidth: '60%',
                              verticalAlign: 'top',
                            }}
                            >
                              {p.productName}
                            </Label>
                          </Row>
                          <Row>
                            <BoldLabel>{i18n.t('machine')}</BoldLabel>
                            <Label>{p.vendingMachineName}</Label>
                          </Row>
                          <Row>
                            <BoldLabel>{i18n.t('merchant')}</BoldLabel>
                            <Label>{p.merchantName}</Label>
                          </Row>
                        </div>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              )}
            </FilteredList>
          </div>
        </ListContainer>
      </div>
    </Root>
  );
};

export default withRouter(NoVMView);

import { formatValue } from 'react-currency-input-field';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router';
import i18n from '../../i18n/i18n';
import NewLogo from '../../images/logos_super2000_limagengibre.png';
import CardBackground1 from '../../images/card_background_1.jpg';
import MifareLogo from '../../images/mifare_logo.png';
import { ReactComponent as MBWayLogo } from '../../images/MBWAY_PagAutorizados.svg';

const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
`;

const CARD_STYLE = styled.div`
  position: relative;
  width: 100%;
  min-height: 160px;
  text-align: center;
  background: url(${CardBackground1}) no-repeat 0 0 white;
  background-size: cover;
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.60);
`;

const Chip = styled.div`
  width: 28%;
  height: 3.3vh;
  background: url(${(props) => (props.gatewayName === 'TOKEN' ? MifareLogo : MBWayLogo)}) no-repeat 0 0;
  background-size: contain;
  border-radius: 8px;
  position: relative;
  margin-left: 20px;
`;

const StyledMbWayLogo = styled(MBWayLogo)`
  width: 100%;
  height: 14vh;
`;

const Logo = styled.div`
  width: 28%;
  height: 6vh;
  padding-right: 20px;
  background: url(${NewLogo}) no-repeat 0 0;
  background-size: contain;
`;

const PrivateCard = ({
  paymentMethod, walletAmount, color,
}) => {
  const currentLanguage = useMemo(
    () => `${i18n.language[0]}${i18n.language[1]}-${i18n.language[2]}${i18n.language[3]}`,
    [i18n.language],
  );

  const history = useHistory();

  switch (paymentMethod?.gatewayName) {
    case 'TOKEN':
      return (
        <CARD_STYLE key={paymentMethod.id}>
          <div style={{
            paddingTop: '8%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}
          >
            <Chip gatewayName="TOKEN" />
            <Logo />
          </div>
          <>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'row',
            }}
            >
              <div style={{
                color: 'rgb(28,28,28)',
                display: 'flex',
                marginLeft: 20,
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'flex-start',
                position: 'relative',
                textShadow: '-0.5px -0.5px 0px rgba(255,255,255,0.3), 0.5px 0.5px 0px rgba(0,0,0,0.8)',
                letterSpacing: 2,
              }}
              >
                <h2 style={{
                  textAlign: 'left',
                  fontSize: '1.5em',
                  textShadow: '-1px -1px 0px rgba(255,255,255,0.3), 0.5px 0.5px 0px rgba(0,0,0,0.8)',
                  letterSpacing: 2,
                  color,
                }}
                >
                  {paymentMethod?.wallet?.number}
                </h2>
                <span style={{
                  textAlign: 'left',
                  fontSize: '1em',
                  textShadow: '-1px -1px 0px rgba(255,255,255,0.3), 0.5px 0.5px 0px rgba(0,0,0,0.8)',
                  letterSpacing: 2,
                }}
                >
                  {paymentMethod?.wallet?.tokenSchema}
                </span>
                <StyledSpan style={{ fontSize: '1em' }}>
                  <span>
                    {paymentMethod?.wallet?.currencyCode === 'XXX'
                      ? `${walletAmount} ${i18n.t('services')}`
                      : formatValue({
                        value: walletAmount,
                        groupSeparator: ',',
                        decimalSeparator: '.',
                        decimalScale: 2,
                        intlConfig: {
                          locale: currentLanguage,
                          currency: paymentMethod?.wallet?.currencyCode,
                        },
                      })}
                  </span>
                </StyledSpan>
              </div>
              <div
                style={{
                  color,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  height: '80px',
                  marginRight: '10px',
                }}
              >
                {paymentMethod?.wallet?.isWalletRevaluable
                    && (
                      <AddCircleIcon
                        fontSize="large"
                        onClick={() => {
                          if (paymentMethod?.wallet?.isWalletRevaluable) {
                            history.push('/revalue-amount-selection', { wallet: paymentMethod?.wallet, color });
                          }
                        }}
                      />
                    )}
              </div>
            </div>
          </>
        </CARD_STYLE>
      );
    case 'MBWAY':
    case 'SPG':
      return (
        <CARD_STYLE key={paymentMethod.id}>
          <div style={{
            paddingTop: '8%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}
          >
            <StyledMbWayLogo />
          </div>
        </CARD_STYLE>
      );
    default:
      return null;
  }
};

export default PrivateCard;

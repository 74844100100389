import NetworkTransport from '@elecctro/network-transport/lib';
import qs from 'qs';
import {
  OpenSessionResponseType, PaymentMethodType, Purchase, VendingMachineInfo, SessionInfo, RevalueTokenResponseType,
} from '../types/types';

export type StateType =
  | -200 // CREDIT_STATUS_FAILED
  | -201 // CREDIT_STATUS_FAILED_UNABLE_TO_CONNECT
  | -202 // CREDIT_STATUS_FAILED_VENDING_MACHINE_NOT_CONFIGURED
  | -203 // CREDIT_STATUS_FAILED_VENDING_MACHINE_PROTOCOL_TIMEOUT
  | -100 // CREDIT_STATUS_DENIED
  | -101 // CREDIT_STATUS_DENIED_VENDING_MACHINE_HAS_CREDIT
  | -102 // CREDIT_STATUS_DENIED_VENDING_MACHINE_HAS_ACTIVE_SESSION
  | -103 // CREDIT_STATUS_DENIED_WALLET_IN_USE
  | -104 // CREDIT_STATUS_DENIED_INSUFFICIENT_FUNDS
  | -105 // CREDIT_STATUS_DENIED_DIFFERENT_CURRENCY
  | -106 // CREDIT_STATUS_DENIED_UNDER_MIN_GATEWAY_AMOUNT
  | -107 // CREDIT_STATUS_DENIED_OVER_MAX_GATEWAY_AMOUNT
  | -108 // CREDIT_STATUS_DENIED_GATEWAY_IS_INACTIVE
  | 0 // CREDIT_STATUS_REQUESTED
  | 100 // CREDIT_STATUS_ESTABLISHED
  | 200 // CREDIT_STATUS_TERMINATION_REQUESTED
  | 300 // CREDIT_STATUS_TERMINATED
  | 310 // CREDIT_STATUS_TERMINATED_SERVER_INITIATED
  | 321 // CREDIT_STATUS_TERMINATED_VMU_TIMEOUT
  | 322 // CREDIT_STATUS_TERMINATED_VMU_PROTOCOL_RESET
  | 323 // CREDIT_STATUS_TERMINATED_VMU_PROTOCOL_UNABLE_TO_INITIATE
  | 324 // CREDIT_STATUS_TERMINATED_VMU_PROTOCOL_STATE_IS_INVALID
  | 325 // CREDIT_STATUS_TERMINATED_VMU_DISCONNECTED_FROM_HUB
  | 326 // CREDIT_STATUS_TERMINATED_VMU_DISCONNECTED_FROM_SERVER
  | 327; // CREDIT_STATUS_TERMINATED_VMU_APPLICATION_ERROR

export type SessionType = {
  id: string;
  state: StateType;
  transactionState: {
    name:
      | 'PURCHASE_SENT'
      | 'PURCHASE_PENDING'
      | 'REFUND_SENT'
      | 'REFUND_PENDING';
  };
};

export const URL = process.env.REACT_APP_SERVER_URL || '';

export const server: NetworkTransport = new NetworkTransport(URL, {
  autoConnectSocket: true,
});

export default server;

export const dummyPause = async (time: number = 1000) => new Promise<void>((resolve) => {
  setTimeout(() => {
    resolve();
  }, time);
});

export const getVendingMachineInfo = async (stickerId: string = ''): Promise<VendingMachineInfo> => {
  const response = await server.fetch(
    `/customer/vendingMachines/${stickerId}`,
    { method: 'GET' },
  );

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const getCustomerPurchasesHistory = async (vendingMachineId): Promise<Purchase[]> => {
  const parameters = {
    vendingMachineId,
  };

  let url;

  if (vendingMachineId) {
    url = `/customer/purchases?${qs.stringify(parameters)}`;
  } else {
    url = '/customer/purchases';
  }

  const response = await server.fetch(
    url,
    {
      method: 'GET',
    },
  );

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const getCustomerTransactionsHistory = async (vendingMachineId, walletId, tab): Promise<Purchase[]> => {
  const parameters = {
    ...(vendingMachineId && { vendingMachineId }),
    ...(walletId && { tokenWalletId: walletId }),
    type: tab,
  };

  const response = await server.fetch(`/customer/transactionsHistory?${qs.stringify(parameters)}`,
    {
      method: 'GET',
    });

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const requestMandate = async (
  vendingMachineId: string | undefined,
  currency: string | undefined,
  gatewayName: string,
  mobilePhone: string | undefined) => {
  const response = await server.fetch('/customer/addPaymentToken', {
    method: 'POST',
    body: {
      vendingMachineId,
      currency: currency || 'EUR',
      gatewayName,
      gatewayParameters: {
        mobilePhone,
      },
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const openSession = async (
  vendingMachineId: string,
  paymentMethod: PaymentMethodType,
): Promise<OpenSessionResponseType> => {
  const response = await server.fetch('/vendingMachine/session/open', {
    method: 'POST',
    body: {
      vendingMachineId,
      source: 'CUSTOMER_APP',
      ...paymentMethod,
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const revalueToken = async (
  tokenWalletId: string,
  amountToCredit: number,
  currency: string,
  gatewayName: string,
  gatewayParameters: { mobilePhone: string },
): Promise<RevalueTokenResponseType> => {
  const response = await server.fetch('/customer/revalueTokenWallet', {
    method: 'POST',
    body: {
      tokenWalletId,
      amount: amountToCredit,
      currency: currency || 'EUR',
      gatewayName,
      gatewayParameters,
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const selectProduct = async (
  vendingMachineId: string,
  sessionId: string | undefined,
  productKey: string | null,
  itemNumber: number,
  sugarLevel: number | null) => {
  const response = await server.fetch('/customer/session/selectProduct', {
    method: 'POST',
    body: {
      vendingMachineId,
      sessionId,
      ...(productKey ? { productKey } : {}),
      itemNumber,
      sugarLevel,
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const closeSession = async (sessionId: string) => {
  const response = await server.fetch('/vendingMachine/session/close', {
    method: 'POST',
    body: {
      sessionId,
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw response;
};

export const register = async (name: string | null, email: string | null,
  password: string | null, stickerId: string | undefined) => {
  const response = await server.fetch('/customers', {
    method: 'PUT',
    body: {
      name,
      email,
      password,
      stickerId,
      locale: 'pt',
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const activateAccount = async (token, stickerId) => {
  const response = await server.fetch('/customer/activate', {
    method: 'POST',
    body: {
      token,
      stickerId,
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw await response.json();
};

export const getDetails = async (auth) => {
  const response = await server.fetch(`/customer?token=${auth.token}`, {
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const getMandates = async (gatewayName, vendingMachineId) => {
  const parameters = {
    gatewayName,
    vendingMachineId,
  };

  const response = await server.fetch(`/customer/paymentTokens?${qs.stringify(parameters)}`, {
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const getCustomerWallets = async (auth) => {
  const response = await server.fetch(`/customer/wallets?token=${auth.token}`, {
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const getWalletRevalues = async (auth, walletId) => {
  const response = await server.fetch(`/customer/wallets/${walletId}/revalueHistory?token=${auth.token}`, {
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const updateProfile = async (
  auth,
  name: string | undefined,
  mobilePhone: string | null) => {
  const response = await server.fetch(`/customer?token=${auth.token}`, {
    method: 'PATCH',
    body: {
      name,
      mobilePhone,
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const login = async (email: string | undefined, password: string | undefined) => {
  const response = await server.fetch('/customer/login', {
    method: 'POST',
    body: {
      email,
      password,
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const logout = async (auth) => {
  const response = await server.fetch(`/customer/logout?token=${auth.token}`, {
    method: 'POST',
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const changePassword = async (auth, oldPassword: string | null, newPassword: string | null) => {
  const response = await server.fetch(`/customer/password?token=${auth.token}`, {
    method: 'PATCH',
    body: {
      oldPassword,
      newPassword,
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const resetPassword = async (email) => {
  const response = await server.fetch('/customer/password/reset', {
    method: 'POST',
    body: { email, locale: 'pt' },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const confirmPasswordReset = async (token, password) => {
  const response = await server.fetch('/customer/password/reset/confirm', {
    method: 'POST',
    body: {
      token,
      password,
    },
  });

  if (response.ok) {
    return response.json();
  }
  throw response;
};

export const getDocuments = async (sessionId: string = ''): Promise<SessionInfo> => {
  const response = await server.fetch(
    `/customer/docs/${sessionId}`,
    { method: 'GET' },
    'socket',
  );

  if (response.ok) {
    return response.json();
  }

  throw response;
};

import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { parse as uuidParse } from 'uuid';
import Slider from 'rc-slider';
import { ClipLoader } from 'react-spinners';
import * as qs from 'qs';
import { formatValue } from 'react-currency-input-field';
import Button from '@material-ui/core/Button';
import { Cancel, CheckCircle } from '@mui/icons-material';
import Accordion from '../components/accordion';
import AccordionItem from '../components/accordion-item';
import { useSession } from '../contexts/SessionContext';
import { ReactComponent as Signal } from '../images/signal.svg';
import LargeButton from '../components/LargeButton';
import { ReactComponent as KeypadSvg } from '../images/keypad.svg';
import BackgroundImage from '../components/BackgroundImage';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import BackButton from '../components/BackButton';
import { Product } from '../types/types';
import { closeSession, selectProduct } from '../api/api';
import 'rc-slider/assets/index.css';
import i18n from '../i18n/i18n';
import { ReactComponent as MBWayLogo } from '../images/MBWAY_PagAutorizados.svg';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  max-height: 75vh;
`;

const Message = styled.span`
  text-align: center;
  font: 1.6em Poppins;
  margin-bottom: 20px;
  white-space: pre-line;
`;

const StyledMbWayLogo = styled(MBWayLogo)`
  height: 20%;
`;

const ImageContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  user-select: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 45%;
`;

const ListProducts = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 99%;
  overflow-y: auto;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 160px;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const Label = styled.span`
  font-size: 1em;
  min-width: 110px;
  display: inline-block;
`;

export default ({ history, location }) => {
  const {
    watchSessionState,
    watchSessionBalance,
    sessionBalance,
    sessionError,
    watchSessionError,
    selectedPaymentMethod,
    sessionId,
    reset,
  } = useSession();
  const { vendingMachineInfo, stickerId } = useVendingMachine();
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [sessionState, setSessionState] = useState<any>('CONNECTING');
  const [localSessionBalance, setLocalSessionBalance] = useState<any>(sessionBalance.current);
  const [localSessionError, setLocalSessionError] = useState<any>(sessionError.current);
  const timerRef = useRef<any>(null);
  const [isProductsListVisible, setProductsListVisible] = useState<boolean>(false);
  const [isProductSelected, setIsProductSelected] = useState<boolean>(false);
  const [isKeypadVisible, setIsKeypadVisible] = useState<boolean>(true);
  const [failedPaymentMethod, setFailedPaymentMethod] = useState<any>(null);

  const sliderMarks = {
    1: {
      style: { color: 'white' },
      label: i18n.t('no_sugar'),
    },
    7: {
      style: { color: 'white' },
      label: i18n.t('maximum'),
    },
  };

  const bufferToBase64 = (buf) => {
    const binstr = Array.prototype.map
      .call(buf, (ch) => String.fromCharCode(ch))
      .join('');

    return btoa(binstr);
  };

  const onProductSelected = (product: Product, sugarLevel: number) => {
    setIsProductSelected(true);
    setProductsListVisible(false);
    setIsKeypadVisible(false);
    if (vendingMachineInfo?.id !== undefined) {
      const parsedItemNumber = parseInt(product.itemNumber, 10);
      let chosenSugarLevel: number | null = null;
      if (product.supportCustomSugar) {
        chosenSugarLevel = sugarLevel;
      }
      selectProduct(vendingMachineInfo?.id, sessionId.current, null, parsedItemNumber, chosenSugarLevel);
    }
  };

  const onProductSelectedCallback = useCallback(onProductSelected, [onProductSelected]);

  const handleGetDocument = (sessionIdToDocument?: string) => {
    const sessionIdBase64 = sessionIdToDocument && bufferToBase64(uuidParse(sessionIdToDocument))
      .replace(/=+$/, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    window.open(`${process.env.REACT_APP_SERVER_URL}/customer/document/${sessionIdBase64}`);
  };

  useEffect(() => {
    watchSessionState((newSessionState) => setSessionState(newSessionState));
    watchSessionBalance((newBalance) => setLocalSessionBalance(newBalance));
    watchSessionError((newError) => setLocalSessionError(newError));
  }, []);

  useEffect(() => {
    if (!vendingMachineInfo && !s) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s) {
      history.push(`/load?s=${s}`);
    }

    if (localSessionError) {
      setFailedPaymentMethod(selectedPaymentMethod);
      reset();
      setTimeout(() => history.push(`/load?s=${stickerId}`), 5000);
    } else if ((sessionState === 'PICKUP_PRODUCT'
        || (sessionState === 'PAYMENT_CONFIRMED'
          && (vendingMachineInfo?.equipmentType?.label === 'EV Charger'
            || vendingMachineInfo?.equipmentType?.label === 'Smart Fridge')))
      && timerRef.current === null) {
      timerRef.current = setTimeout(() => {
        reset();
        history.push(`/load?s=${stickerId}`);
      }, 15000);
    }
  }, [
    sessionState,
    localSessionError,
    localSessionBalance,
    isProductsListVisible,
    isKeypadVisible,
    isProductSelected,
  ]);

  return (
    <BackgroundImage
      equipmentTypeId={vendingMachineInfo?.equipmentType.id}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100%"
        margin="2vh"
      >
        {localSessionError
          && (
            <>
              <Cancel sx={{ color: 'red', fontSize: '50px' }} />
              <Message>{localSessionError}</Message>
              {failedPaymentMethod?.gatewayName?.startsWith('TOKEN')
                && vendingMachineInfo?.equipmentType?.label === 'Smart Fridge'
                && <Message>{i18n.t('smartfridge_min_value_5')}</Message>}
            </>
          )}
        {!localSessionError && sessionState === 'CONNECTING'
          && (vendingMachineInfo?.equipmentType?.label !== 'EV Charger'
            && vendingMachineInfo?.equipmentType?.label !== 'Smart Fridge') && (
            <>
              <Signal style={{ width: 'auto', height: '25%' }} />
              <Message>{i18n.t('establishing_connection')}</Message>
            </>
        )}
        {!localSessionError && sessionState === 'CONNECTING'
          && (vendingMachineInfo?.equipmentType?.label === 'EV Charger'
            || vendingMachineInfo?.equipmentType?.label === 'Smart Fridge') && (
            <>
              <Message>{i18n.t('unlocking_fridge')}</Message>
            </>
        )}
        {!localSessionError && sessionState === 'PRODUCT_SELECTION' && (
          <>
            {selectedPaymentMethod?.gatewayName?.startsWith('TOKEN')
              && vendingMachineInfo?.equipmentType?.label !== 'EV Charger'
              && vendingMachineInfo?.equipmentType?.label !== 'Smart Fridge' && (
                <>
                  <Centered>
                    {!isProductSelected && (
                      <>
                        <Typography style={{
                          fontSize: '2em',
                          fontWeight: 'bold',
                        }}
                        >
                          {`${(localSessionBalance / 100).toFixed(2)} €`}
                        </Typography>
                        <Message>{i18n.t('insert_coins_to_revalue')}</Message>
                      </>
                    )}
                    {!isProductSelected
                      && !isProductsListVisible
                      && (
                        <>
                          <Message>{i18n.t('select_product_machine')}</Message>
                          <ImageContainer>
                            <KeypadSvg />
                          </ImageContainer>
                        </>
                      )}
                    {!isProductSelected
                      && !isProductsListVisible
                      && vendingMachineInfo
                      && vendingMachineInfo.supportRemoteProductSelection && (
                        <ButtonsContainer>
                          <StyledButton
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              setProductsListVisible(true);
                              setIsKeypadVisible(false);
                            }}
                          >
                            {i18n.t('select_product')}
                          </StyledButton>
                        </ButtonsContainer>
                    )}
                    {!isProductSelected
                      && isProductsListVisible && (
                        <>
                          <Message>{i18n.t('list_products_available')}</Message>
                          <ListProducts>
                            <Accordion
                              key={Math.random()}
                            >
                              {vendingMachineInfo?.products.map((product: Product) => (
                                <ProductItem
                                  key={product.id}
                                  product={product}
                                  sliderMarks={sliderMarks}
                                  onProductSelected={onProductSelectedCallback}
                                />
                              ))}
                            </Accordion>
                          </ListProducts>
                        </>
                    )}
                    {isProductSelected && (
                      <Message>{i18n.t('product_selected')}</Message>
                    )}
                  </Centered>
                </>
              )}
            {selectedPaymentMethod?.gatewayName?.startsWith('TOKEN')
              && (vendingMachineInfo?.equipmentType?.label === 'EV Charger'
                || vendingMachineInfo?.equipmentType?.label === 'Smart Fridge') && (
                <>
                  <CheckCircle sx={{ color: 'green', fontSize: '50px' }} />
                  <Message>{i18n.t('fridge_unlock_collect_product')}</Message>
                </>
              )}
          </>
        )}
        {!localSessionError && sessionState === 'PRODUCT_SELECTION' && (
          <>
            {(selectedPaymentMethod?.gatewayName?.startsWith('CARD')
              || selectedPaymentMethod?.gatewayName?.startsWith('SPG')) && (
              <>
                <Centered>
                  {!isProductSelected
                    && !isProductsListVisible
                    && (<Message>{i18n.t('select_product_machine')}</Message>)}
                  {!isProductSelected
                    && !isProductsListVisible
                    && vendingMachineInfo
                    && vendingMachineInfo.supportRemoteProductSelection && (
                      <ButtonsContainer>
                        <StyledButton
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setProductsListVisible(true);
                            setIsKeypadVisible(false);
                          }}
                        >
                          <span>{i18n.t('select_product')}</span>
                        </StyledButton>
                      </ButtonsContainer>
                  )}
                  {!isProductSelected
                    && isProductsListVisible && (
                      <>
                        <Message>{i18n.t('list_products_available')}</Message>
                        <ListProducts>
                          <Accordion
                            key={Math.random()}
                          >
                            {vendingMachineInfo?.products.map((product: Product) => (
                              <ProductItem
                                key={product.id}
                                product={product}
                                sliderMarks={sliderMarks}
                                onProductSelected={onProductSelectedCallback}
                              />
                            ))}
                          </Accordion>
                        </ListProducts>
                      </>
                  )}
                  {isProductSelected && (
                    <Message>{i18n.t('product_selected')}</Message>
                  )}
                </Centered>
              </>
            )}
          </>
        )}
        {!localSessionError && sessionState === 'WAITING_FOR_PAYMENT'
          && (
            <>
              {(vendingMachineInfo?.equipmentType?.label === 'EV Charger'
                  || vendingMachineInfo?.equipmentType?.label === 'Smart Fridge')
                && <StyledMbWayLogo />}
              <Message>{i18n.t('approve_purchase_MBWay')}</Message>
              <ClipLoader loading size={50} color="rgba(49,91,70,255)" />
            </>
          )}
        {!localSessionError && sessionState === 'PAYMENT_CONFIRMED'
          && vendingMachineInfo?.equipmentType?.label !== 'EV Charger'
          && vendingMachineInfo?.equipmentType?.label !== 'Smart Fridge'
          && (
            <>
              <Message>{i18n.t('serving')}</Message>
              <ClipLoader loading size={50} color="rgba(49,91,70,255)" />
            </>
          )}
        {!localSessionError && sessionState === 'PAYMENT_CONFIRMED'
          && (vendingMachineInfo?.equipmentType?.label === 'EV Charger'
            || vendingMachineInfo?.equipmentType?.label === 'Smart Fridge')
          && (
            <>
              <CheckCircle sx={{ color: 'green', fontSize: '50px' }} />
              <Message>{i18n.t('fridge_unlock_collect_product')}</Message>
            </>
          )}
        {!localSessionError && sessionState === 'PICKUP_PRODUCT' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CheckCircle sx={{ color: 'green', fontSize: '50px' }} />
            <Message>{i18n.t('collect_product')}</Message>
            {selectedPaymentMethod?.gatewayName === 'CARD' && (
              <LargeButton
                onClick={() => handleGetDocument(sessionId)}
                style={{ marginBottom: '1em' }}
              >
                {i18n.t('consult_document')}
              </LargeButton>
            )}
            <div style={{ height: '60%' }} />
            <div style={{ flex: 1 }} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }} />
          </div>
        )}
      </Box>
      <div style={{ flex: 1 }} />
      {['PICKUP_PRODUCT', 'PRODUCT_SELECTION', 'PAYMENT_CONFIRMED'].includes(sessionState)
        && (
          <>
            {vendingMachineInfo?.equipmentType?.label !== 'Smart Fridge'
              ? (
                <BackButton backHandler={async () => {
                  if (sessionState === 'PRODUCT_SELECTION' && !localSessionError) {
                    await closeSession(sessionId.current);
                  }
                  reset();
                  clearTimeout(timerRef.current);
                  timerRef.current = null;
                  history.push(`/load?s=${stickerId}`);
                }}
                />
              )
              : (
                <ButtonsContainer>
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => history.push(`/load?s=${stickerId}`)}
                  >
                    {i18n.t('proceed')}
                  </StyledButton>
                </ButtonsContainer>
)}
          </>
        )}
    </BackgroundImage>
  );
};

const ProductItem = ({
  product, sliderMarks, onProductSelected,
}) => {
  const [innerSugar, setInnerSugar] = useState<number>(4);

  const currentLanguage = useMemo(
    () => `${i18n.language[0]}${i18n.language[1]}-${i18n.language[2]}${i18n.language[3]}`,
    [i18n.language],
  );

  return (
    <AccordionItem
      headerPaddingY={5}
      header={(
        <>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '60%',
            justifyContent: 'start',
            alignItems: 'start',
            height: '100%',
            fontSize: '0.85em',
          }}
          >
            <Typography style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'block',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '1em',
            }}
            >
              <b>{product.productName}</b>
            </Typography>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '30%',
            justifyContent: 'right',
            alignItems: 'center',
            height: '100%',
            fontSize: '0.85em',
          }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '80%',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
            }}
            >
              <Typography
                style={{
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                {formatValue({
                  value: (product.price / 100).toFixed(2),
                  groupSeparator: ',',
                  decimalSeparator: '.',
                  decimalScale: 2,
                  intlConfig: { locale: currentLanguage, currency: 'EUR' },
                })}
              </Typography>
            </div>
          </div>
        </>
      )}
    >
      <div>
        <Typography>
          <Label>
            {i18n.t('item_number')}
            :
            &nbsp;
          </Label>
          <span style={{
            display: 'inline-block',
            maxWidth: '60%',
            fontFamily: 'Poppins, sans-serif',
          }}
          >
            {product.itemNumber}
          </span>
        </Typography>
        <div>
          {product.supportCustomSugar && (
            <>
              <Typography>
                <Label style={{ marginTop: 10 }}>{i18n.t('sugar')}</Label>
                <span style={{
                  display: 'inline-block',
                  maxWidth: '60%',
                  fontFamily: 'Poppins, sans-serif',
                }}
                />
              </Typography>
              <Slider
                style={{
                  width: 'auto',
                  margin: '10px 20px 20px 20px',
                  height: 15,
                }}
                handleStyle={{
                  borderColor: 'rgb(52, 92, 68)',
                  height: 23,
                  width: 23,
                  backgroundColor: 'rgb(52, 92, 68)',
                }}
                marks={sliderMarks}
                step={1}
                min={1}
                max={7}
                // defaultValue={productSugarLevel}
                value={innerSugar}
                onChange={(level: number | number[]) => {
                  if (!Array.isArray(level)) {
                    setInnerSugar(level);
                  }
                }}
                dots
                dotStyle={{
                  borderColor: 'rgb(52, 92, 68)', height: 14, width: 14, top: -1,
                }}
                railStyle={{ backgroundColor: 'white', height: 12 }}
                trackStyle={{ backgroundColor: 'rgb(52, 92, 68)', height: 12 }}
              />
            </>
          )}
        </div>
        <ButtonsContainer>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={() => onProductSelected(product, innerSugar)}
          >
            {i18n.t('buy')}
          </StyledButton>
        </ButtonsContainer>
      </div>
    </AccordionItem>
  );
};

import React from 'react';
import QrReader from 'react-qr-scanner';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const READ_DELAY = 500;

export default function ReadQR({ history }) {
  const { t } = useTranslation();

  const handleScan = (data: any) => {
    if (data && data.text) {
      const stickerIdRaw = data.text.split('?s=');
      if (stickerIdRaw.length !== 2) {
        return;
      }
      const stickerId = stickerIdRaw[1];
      history.push(`/load?s=${stickerId}`);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const constraints = {
    video: {
      facingMode: 'environment',
      aspectRatio: { ideal: 1}
    },
  };

  return (
    <Root>
      <div style={{
        background: 'linear-gradient(90deg, rgb(52,92,68) 0%, rgb(195,198,119) 100%)',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <h1 style={{ color: 'white' }} >
          {t('please_present_the_qrcode')}
        </h1>
        <QrReader
          delay={READ_DELAY}
          videoStyle={{ width: '100%', height: '100%'}}
          constraints={constraints}
          onError={handleError}
          onScan={handleScan}
        />
        <Footer showBackButton={false} screen={false} />
      </div>
    </Root>
  );
}

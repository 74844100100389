import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Add as AddIcon,
  ShoppingCart as ShoppingCartIcon,
  QrCodeScanner as QrCodeScannerIcon,
  AddCard as AddCardIcon,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import i18n from '../i18n/i18n';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { getDetails, requestMandate } from '../api/api';
import { useUser } from '../contexts/UserContext';
import {useSession} from "../contexts/SessionContext";
import PhoneNumber from "awesome-phonenumber";

interface IconContainerProps {
  disabled?: boolean;
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: rgb(52, 92, 68);
`;

const IconContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24.5%;
`;

const IconDescription = styled.span<{ disabled?: boolean }>`
  padding: 4px;
  font: 13px Poppins;
  text-align: center;
  display: table;
  z-index: 2;
  color: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.4)' : 'white')};
`;

const iconsData = [
  {
    name: 'shop', icon: ShoppingCartIcon, label: 'shop', disabled: false,
  },
  {
    name: 'qrCodeScanner', icon: QrCodeScannerIcon, label: 'read_qr', disabled: false,
  },
  {
    name: 'addCard', icon: AddCardIcon, label: 'add_card', disabled: false,
  },
];

interface ActionButtonsListProps {
  history: any;
  paymentMethod: any;
  menu: string;
}

const ActionButtons: React.FC<ActionButtonsListProps> = ({
  history,
  paymentMethod,
  menu,
}) => {
  const filteredIconsData = menu === 'initialMenu'
    ? iconsData.filter(({ name }) => name !== 'shop')
    : iconsData.filter(({ name }) => name !== 'qrCodeScanner');
  const disabledIcons = ['addCard'];

  const { stickerId } = useVendingMachine();
  const { selectPaymentMethod } = useSession();

  const onClickHandler = async (name: string) => {
    switch (name) {
      case 'shop':
        if (paymentMethod.gatewayName === 'SPG' || paymentMethod.gatewayName === 'MBWAY') {
          history.push(`/mbway?s=${stickerId}`, { paymentMethod });
        } else {
          selectPaymentMethod({
            gatewayName: 'TOKEN',
            gatewayParameters: { walletId: paymentMethod?.wallet?.id },
            maxCredit: paymentMethod?.wallet?.maxCredit,
          }, paymentMethod?.wallet?.balance);
          history.push(`/product-selection?s=${stickerId}`);
        }
        break;
      case 'qrCodeScanner':
        history.push('/readQr');
        break;
      /* case 'addCard':
        break; */
      default:
        break;
    }
  };

  return (
    <IconContainer>
      {filteredIconsData.map(({
        name, icon: IconComponent, label,
      }) => {
        const isIconDisabled = disabledIcons.includes(name);

        return (
          <IconContent
            key={name}
          >
            <IconButton
              aria-label={label}
              size="large"
              style={{
                fontSize: '1.5rem',
                backgroundColor: 'rgba(255,255,255,0.4)',
                color: isIconDisabled ? '' : 'rgb(255, 255, 255)',
              }}
              disabled={isIconDisabled}
              onClick={() => {
                onClickHandler(name);
              }}
            >
              <IconComponent fontSize="inherit" />
            </IconButton>
            <IconDescription disabled={isIconDisabled}>
              {i18n.t(label)}
            </IconDescription>
          </IconContent>
        );
      })}
    </IconContainer>
  );
};
export default ActionButtons;

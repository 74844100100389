import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import * as qs from 'qs';
import { Box, CircularProgress } from '@material-ui/core';
import { formatValue } from 'react-currency-input-field';
import Accordion from '../components/accordion';
import AccordionItem from '../components/accordion-item';
import Header from '../components/TopHeader';
import i18n from '../i18n/i18n';
import { getCustomerPurchasesHistory } from '../api/api';
import PrivateCardSlider from '../components/private-card-slider';
import ActionButtons from '../components/ActionButtons';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import { Purchase } from '../types/types';
import { getDate, getTime } from '../utils/DateFormatter';
import ProductListView from './ProductListView';
import getImageFromGateway from '../utils/GatewayImages';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const ListFiltersContainer = styled.div`
  justify-content: start;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 1%;
  width: 100%;
  height: 11%;
  min-height: 6vh;
`;

const FilteredList = styled.div`
  display: flex;
  padding-top: 4px;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
  position: relative;
  background: white;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 88%;
`;

const ListItemsContainer = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-direction: column;

`;

const FilterButton = styled.div<{ selected?: boolean }>`
  flex: 1;
  padding: 2%;
  background: ${(props) => (props.selected ? 'rgb(52,92,68)' : 'rgb(241,241,241)')};
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
  color: ${(props) => (props.selected ? 'rgb(255,255,255)' : 'rgb(161,161,161)')};
  font: 1em Poppins;
`;

const Spacer = styled.div<any>`
  min-width: ${(props: any) => (props.width ? props.width : '10px')};
`;

const Label = styled.span`
  font-size: 0.85em;
  min-width: 110px;
  display: inline-block;
`;

const BoldLabel = styled.span`
  font-size: 0.85em;
  font-weight: bold;
  min-width: 110px;
  display: inline-block;
  width: 40%;
`;

const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const VMView = ({ history, location }) => {
  const [dummy, setDummy] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(undefined);
  const [vendingMachinePaymentMethods, setVendingMachinePaymentMethods] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const { vendingMachineInfo } = useVendingMachine();
  const [vmProducts, setVmProducts] = useState<any[]>([]);
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [error, setError] = useState<boolean>(false);

  const backRef = useRef<HTMLDivElement>(null);
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const currentLanguage = useMemo(
    () => `${i18n.language[0]}${i18n.language[1]}-${i18n.language[2]}${i18n.language[3]}`,
    [i18n.language],
  );

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!vendingMachineInfo && !s) {
      history.push('/home-no-vm');
    } else if (!vendingMachineInfo && s) {
      history.push(`/load?s=${s}`);
    }

    if (vendingMachineInfo?.products) {
      setVmProducts(vendingMachineInfo.products);
    }

    (async () => {
      try {
        const purchasesList = await getCustomerPurchasesHistory(vendingMachineInfo?.id);
        setPurchases(purchasesList);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(true);
      } finally {
        setTimeout(() : void => {
          setDummy(!dummy);
        }, 200);
      }
    })();
  }, [vendingMachineInfo]);

  useEffect(() => {
    const artificialPaymentMethods: any[] = [];

    if (!vendingMachineInfo) {
      setShowNotification(true);
    }

    const paymentMethods: any = vendingMachineInfo?.paymentTerminals
      .filter((filteredPaymentMethod) => filteredPaymentMethod.gatewayName === 'TOKEN'
        || filteredPaymentMethod.gatewayName === 'SPG' || filteredPaymentMethod.gatewayName === 'MBWAY');

    if (paymentMethods?.length > 0) {
      paymentMethods.map((individualPaymentMethod: any) => {
        switch (individualPaymentMethod.gatewayName) {
          case 'TOKEN': {
            const a = individualPaymentMethod?.wallets?.forEach((tokenWallet: any) => {
              artificialPaymentMethods.push({
                ...individualPaymentMethod,
                wallets: undefined,
                wallet: tokenWallet,
              });
            });
            console.log(a);
            break;
          }
          case 'SPG':
          case 'MBWAY': {
            artificialPaymentMethods.push({
              ...individualPaymentMethod,
              wallet: null,
            });
            break;
          }
          default:
            break;
        }

        return individualPaymentMethod;
      });
    }

    setPaymentMethod(artificialPaymentMethods?.[0]);
    setVendingMachinePaymentMethods(artificialPaymentMethods);
  }, [vendingMachineInfo]);

  // @ts-ignore
  const backgroundHeight = backRef !== null ? (backRef?.current?.clientHeight + 40) || '57%' : '57%';

  return (
    <Root style={{ background: '#d9d9d9', zIndex: 0 }}>
      <div style={{
        background: 'linear-gradient(90deg, rgb(52,92,68) 0%, rgb(195,198,119) 100%)',
        position: 'absolute',
        width: '100%',
        height: backgroundHeight,
        zIndex: 0,
      }}
      />
      <div
        ref={backRef}
        style={{
          flex: 5,
        }}
      >
        <Header marginBottom="20px" showVm userLogo cards color="rgb(52,92,68)" />
        <PrivateCardSlider
          paymentMethods={vendingMachinePaymentMethods}
          loading={loading}
          showNotification={showNotification}
          onWalletChange={(selectedWallet) => setPaymentMethod(selectedWallet)}
          isInVendingMachine
          color="rgb(52,92,68)"
        />
        <ActionButtons
          history={history}
          paymentMethod={paymentMethod}
          menu="VmMenu"
        />
      </div>
      <div style={{
        flex: '6 1 1px',
        background: 'rgb(217 217 217)',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0',
        marginTop: '16px',
      }}
      >
        <ListItemsContainer>
          <div style={{
            height: '100%',
            width: '96%',
            overflow: 'hidden',
            paddingTop: '2px',
            borderRadius: '12px 12px 0 0',
            background: 'white',
            zIndex: 1,
          }}
          >
            <ListFiltersContainer>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'start',
                width: '100%',
                paddingBottom: '10px',
                fontSize: '0.8em',
                paddingRight: '16px',
                paddingLeft: '16px',
              }}
              >
                <FilterButton selected={(value === 0)} onClick={() => handleChange(0)}>
                  {i18n.t('products')}
                </FilterButton>
                <Spacer />
                <FilterButton selected={(value === 1)} onClick={() => handleChange(1)}>
                  {i18n.t('purchases')}
                </FilterButton>
              </div>
            </ListFiltersContainer>
            <FilteredList
              style={{
                alignContent: vmProducts?.length === 0 ? 'center' : 'flex-start',
              }}
            >
              {
                value === 0 && vmProducts?.length === 0 && (
                  <>
                    <div style={{ flex: 1 }} />
                    <h2 style={{ textAlign: 'center' }}>{i18n.t('no_products_available')}</h2>
                    <div style={{ flex: 1 }} />
                  </>
                )
              }
              {value === 0 && <ProductListView location={location} currentLanguage={currentLanguage} />}
              {value === 1 && !error && loading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  marginTop="25%"
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}

              {!error && value === 1 && purchases?.length > 0 && (
                <Accordion
                  key={3459384}
                >
                  {purchases.map((p: any) => {
                    const revalueAmount = p?.currency === 'XXX'
                      ? p.amount
                      : (p.amount / 100)
                        .toFixed(2);

                    return (
                      <AccordionItem
                        chevronMarginRight="10px"
                        highlightColor="rgba(99,100,103,255)"
                        header={(
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100% !important',
                              flex: 1,
                              height: '100%',
                            }}
                          >
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '40%',
                              justifyContent: 'start',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              <StyledSpan>{getDate(p.date)}</StyledSpan>
                              <StyledSpan>{getTime(p.date)}</StyledSpan>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '35%',
                              justifyContent: 'center',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              <StyledSpan>
                                {p?.currency === 'XXX'
                                  ? `${revalueAmount} ${i18n.t('services')}`
                                  : formatValue({
                                    value: revalueAmount,
                                    groupSeparator: ',',
                                    decimalSeparator: '.',
                                    decimalScale: 2,
                                    intlConfig: { locale: currentLanguage, currency: p?.currency || 'EUR' },
                                  })}
                              </StyledSpan>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '25%',
                              justifyContent: 'center',
                              alignItems: 'start',
                              height: '100%',
                              fontSize: '0.85em',
                            }}
                            >
                              {getImageFromGateway(p.gateway?.name)}
                            </div>
                          </div>
                        )}
                      >
                        <div style={{ width: 'calc(100% - 34px)' }}>
                          <Row>
                            <BoldLabel>{i18n.t('product')}</BoldLabel>
                            <Label style={{
                              display: 'inline-block',
                              maxWidth: '60%',
                              verticalAlign: 'top',
                            }}
                            >
                              {p.productName}
                            </Label>
                          </Row>
                          <Row>
                            <BoldLabel>{i18n.t('machine')}</BoldLabel>
                            <Label>{p.vendingMachineName}</Label>
                          </Row>
                          <Row>
                            <BoldLabel>{i18n.t('merchant')}</BoldLabel>
                            <Label>{p.merchantName}</Label>
                          </Row>
                        </div>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              )}
              {!error && value === 1 && purchases && purchases.length === 0 && (
              <>
                <div style={{ flex: 1 }} />
                <h2 style={{ textAlign: 'center' }}>{i18n.t('no_purchases_registered')}</h2>
                <div style={{ flex: 1 }} />
              </>
              )}
              {error && (
              <>
                <h2 style={{ textAlign: 'center' }}>{i18n.t('error_ocurred')}</h2>
                <h2 style={{ textAlign: 'center' }}>{i18n.t('try_again_later')}</h2>
              </>
              )}
            </FilteredList>
          </div>
        </ListItemsContainer>
      </div>
    </Root>
  );
};

export default withRouter(VMView);

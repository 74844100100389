export const resetPasswordErrors = Object.freeze({
  INVALID_TOKEN: 'Código de reset inválido',
  PASSWORD_TOO_SIMPLE: 'Palavra-passe muito simples',
  EXPIRED_TOKEN: 'Código de reset expirou, solicite novo código',
});

export const passwordStrengthLabels = Object.freeze([
  'very_weak', 'very_weak', 'weak', 'medium', 'strong',
]);

export const passwordStrengthColors = Object.freeze([
  '#e56625', '#e59f20', '#d9e520', '#99e520', '#20e538',
]);

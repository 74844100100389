import React, { useCallback } from 'react';
import {
  RouteProps,
} from 'react-router-dom';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MdChevronLeft } from 'react-icons/all';

type HeaderProps = {
  backHandler?: any,
  screen?: string,
} & RouteProps

const Root = styled.button`
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 15px;
  left: 15px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 45px;
  text-decoration: none;
  color: white;
  background-color: rgba(52, 92, 68);
  text-align: center;
  cursor: pointer;
  outline: none;
  font-size: 45px;
  font-family: Monospaced, sans-serif;
  &:hover {
    background-color: rgba(52, 92, 68);
  }
  &:active {
    background-color: rgba(52, 92, 68);
    outline: none;
  }
  &:focus {
    background-color: rgba(52, 92, 68);
    outline: none;
  }
`;

const BackButton = ({ backHandler, screen }:HeaderProps) => {
  const history = useHistory();

  const handleBack = useCallback(() => {
    if (screen) {
      history.push(screen);
      return;
    }

    if (backHandler && typeof backHandler === 'function') {
      backHandler();
    }
  }, [backHandler]);

  return (
    <Root onClick={handleBack}>
      <MdChevronLeft />
    </Root>
  );
};

export default BackButton;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControl } from '@material-ui/core';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import { PT, GB, ES } from 'country-flag-icons/react/3x2';
import { ListItemDecorator } from '@mui/joy';
import * as qs from 'qs';
import i18n from '../i18n/i18n';
import BackButton from '../components/BackButton';
import TopHeader from '../components/TopHeader';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

const StyledRadio = styled(Radio)({
  '.css-80pwpb-JoyRadio-radio:hover': {
    color: 'rgb(52, 92, 68) !important',
    borderColor: 'rgb(52, 92, 68) !important',
  },
  '.css-80pwpb-JoyRadio-radio': {
    color: 'rgb(52, 92, 68) !important',
    borderColor: 'rgb(52, 92, 68) !important',
  },
});

const LanguagesView = ({ location }) => {
  const [value, setValue] = useState(i18n.language);
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setValue(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Root>
      <div style={{ flex: 1 }}>
        <TopHeader title="languages" userLogo />
        <FormControl
          style={{
            margin: '0 2vh 2vh 2vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <RadioGroup
            name="people"
            value={value}
            onChange={handleLanguageChange}
          >
            <List
              sx={{
                minWidth: '92vw',
                '--List-gap': '0.5rem',
                '--ListItem-paddingY': '1rem',
                '--ListItem-radius': '8px',
                '--ListItemDecorator-size': '32px',
              }}
            >
              {[{ value: 'ptPT', label: i18n.t('language_portugal') },
                { value: 'enGB', label: i18n.t('language_english') },
                { value: 'esES', label: i18n.t('language_spanish') }]
                .map((item, index) => (
                  <ListItem
                    variant="outlined"
                    key={item.value}
                    sx={{ boxShadow: 'sm', bgcolor: 'background.body', borderRadius: '16px' }}
                  >
                    <ListItemDecorator>
                      {[<PT title="pt-PT" />, <GB title="en-GB" />, <ES title="es-ES" />][index]}
                    </ListItemDecorator>
                    <StyledRadio
                      overlay
                      value={item.value}
                      label={item.label}
                      sx={{
                        flexGrow: 1,
                        flexDirection: 'row-reverse',
                      }}
                      slotProps={{
                        action: ({ checked }) => ({
                          sx: () => ({
                            ...(checked && {
                              inset: -1,
                              border: '2px solid',
                              borderColor: 'rgb(52, 92, 68)',
                              borderRadius: '16px',
                            }),
                          }),
                        }),
                      }}
                    />
                  </ListItem>
                ))}
            </List>
          </RadioGroup>
          <Spacer />
        </FormControl>
        <BackButton screen={`/home${s ? `?s=${s}` : ''}`} />
      </div>
    </Root>
  );
};

export default LanguagesView;

import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import {
  withRouter,
} from 'react-router-dom';
import * as qs from 'qs';
import { CircularProgress } from '@material-ui/core';
import LoginForm from '../components/forms/LoginForm';
import { useUser } from '../contexts/UserContext';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import ResetPasswordDialog from '../components/forms/ResetPasswordDialog';
import Footer from '../components/Footer';
import Logo from '../images/logos_super2000_limagengibre.png';
import LogoSuper2000 from '../images/logo_super2000_vending.jpg';
import i18n from '../i18n/i18n';

const Root = styled.div`
  margin: 0 2vh 2vh 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
`;

const LogoContainer = styled.div`
  height: 30px;
  text-align: center;
  font-size: 0.8rem;
  vertical-align: middle;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex:1
`;

const StyledButton = styled(({ ...rest }) => (
  <Button
    {...rest}
  />
))`
  && {
    background-color: rgb(52, 92, 68);
    border-width: 0;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 120px;
    text-transform: capitalize;
    font: 0.85em Poppins;

    ${({ disabled }) => disabled && `
     background-color: rgb(243, 243, 243);
     color: rgba(255,255,255,0.15)
     border-width: 0;
     border-radius: 10px;
     padding: 10px 20px;
     margin: 10px;
     width: 120px;
     text-transform: capitalize;
     font: 0.85em Poppins;
  `
}
`;

const StyledLink = styled.a`
 text-decoration: underline;
`;

const Spacer = styled.div`
  margin-bottom: 60px;
`;

const LoginView = ({ location, history }) => {
  const { loggedIn } = useUser();
  const { stickerId, vendingMachineInfo } = useVendingMachine();
  const [hasError, setHasError] = React.useState(false);
  const [email, setEmail] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string | null>(null);
  const [isOpenResetPasswordDialog, setOpenResetPasswordDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { s } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleError = useCallback((error) => {
    setHasError(error);
  }, []);

  React.useEffect(() => {
    if (loggedIn && s && !stickerId) {
      history.push(`load?s=${s}`);
      return;
    }

    if (loggedIn && !vendingMachineInfo) {
      history.push('home-no-vm');
      return;
    }

    if (loggedIn && vendingMachineInfo) {
      history.push(`load?s=${stickerId}`);
      return;
    }

    if (!loggedIn && s && !stickerId) {
      history.push(`load?s=${s}`);
    }

    setLoading(false);
  }, [loggedIn, stickerId, history]);

  return loading ? (
    <Root>
      <CircularProgress variant="indeterminate" />
    </Root>
  ) : (
    <Root>
      <div style={{ width: '100%' }}>
        <LogoContainer>
          <img
            src={Logo}
            alt="Logo"
            style={{
              height: '550%',
            }}
          />
        </LogoContainer>
        <h1 style={{ marginTop: '200px' }}>{i18n.t('login')}</h1>
        <LoginForm
          handleError={handleError}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
        />
        <p style={{ marginTop: 0 }}>
          <small>
            {i18n.t('forgot_password')}
            &nbsp;
            <StyledLink
              onClick={() => setOpenResetPasswordDialog(true)}
            >
              {i18n.t('click_here')}
            </StyledLink>
          </small>
        </p>
        <ButtonsContainer>
          <StyledButton
            variant="outlined"
            color="secondary"
            type="submit"
            form="login"
            disabled={hasError}
          >
            {i18n.t('login')}
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={() => history.push({ pathname: 'register', state: { email, password } })}
          >
            {i18n.t('register')}
          </StyledButton>
        </ButtonsContainer>
        <ResetPasswordDialog
          email={email}
          onClose={() => setOpenResetPasswordDialog(false)}
          open={isOpenResetPasswordDialog}
        />
      </div>
      <Spacer />
    </Root>
  );
};

export default withRouter(LoginView);

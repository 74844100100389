import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as qs from 'qs';
import TopHeader from '../components/TopHeader';
import ChangePWForm from '../components/forms/ChangePWForm';
import BackButton from '../components/BackButton';
import i18n from '../i18n/i18n';
import { useVendingMachine } from '../contexts/VendingMachineContext';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const ChangePasswordView = () => (
  <Root>
    <div style={{ flex: 1 }}>
      <TopHeader title="change_password" userLogo />
      <ChangePWForm />
    </div>
    <BackButton screen="home" />
  </Root>
);

export default ChangePasswordView;

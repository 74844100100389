import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';
import PrivateCard from '../private-card';
import i18n from '../../i18n/i18n';
import BackButton from '../BackButton';
import CardSlider from '../card-slider';

const CONTAINER_STYLE = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;
  height: 13em;
`;

const PrivateCardSlider = ({
  paymentMethods,
  loading,
  showNotification,
  onWalletChange,
  isInVendingMachine,
  color,
}) => {
  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        {
          !showNotification && (
            <CircularProgress color="inherit" />
          )
        }
        {
          showNotification && (
            <>
              <div style={{ flex: 1 }} />
              <h2 style={{ textAlign: 'center' }}>{i18n.t('error_occured')}</h2>
              <h2 style={{ textAlign: 'center' }}>{i18n.t('try_again_later')}</h2>
              <div style={{ flex: 1 }} />
              <div style={{ width: '100%' }}>
                <BackButton screen="home-no-vm" style={{ float: 'left' }} />
              </div>
            </>
          )
        }
      </Box>
    );
  }

  const handleWalletChange = (index) => {
    onWalletChange(paymentMethods[index]);
  };

  return (
    <CONTAINER_STYLE>
      <CardSlider onWalletChange={handleWalletChange}>
        {paymentMethods?.map((paymentMethod) => {
          switch (paymentMethod.gatewayName) {
            case 'TOKEN': {
              const amount = isInVendingMachine ? paymentMethod.wallet.balance : paymentMethod.wallet.amount;
              const walletAmount = paymentMethod.wallet.currencyCode === 'XXX' ? amount : (amount / 100).toFixed(2);

              return (
                <PrivateCard
                  key={paymentMethod?.wallet?.id}
                  paymentMethod={paymentMethod}
                  walletAmount={walletAmount}
                  color={color}
                />
              );
            }
            case 'SPG':
            case 'MBWAY':
              return (
                <PrivateCard
                  key={paymentMethod.gatewayName}
                  paymentMethod={paymentMethod}
                  color={color}
                />
              );
            default:
              return null;
          }
        })}
      </CardSlider>
    </CONTAINER_STYLE>
  );
};

export default PrivateCardSlider;

import React, { useEffect } from 'react';
import { RouteProps, withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { FaKey, FaUserEdit } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { BiWorld } from 'react-icons/bi';

import SpeedDial from '@material-ui/lab/SpeedDial';

import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { SpeedDialIcon } from '@material-ui/lab';
import { useUser } from '../contexts/UserContext';
import { useVendingMachine } from '../contexts/VendingMachineContext';
import i18n from '../i18n/i18n';

const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
`;

const HeaderName = styled.h1`
  margin-top: 0;
  margin-bottom: -0.30em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 1.6em Poppins;
`;

const InfoText = styled.div`
  font: 0.8em Poppins;
  padding-bottom: 3%;
  padding-left: 2px;
  color: #5e5e5e;
`;

const RowBackContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding-left: 1em;
  padding-top: 5px;
  min-height: 5vh;
`;

const ExtraContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const StyledSpeedDial = styled(SpeedDial)({
  position: 'absolute',
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: 8,
    right: '1px',
  },
  backgroundColor: 'transparent',
  '.MuiFab-primary': {
    backgroundColor: 'transparent',
  },
  '.MuiButtonBase-root.MuiFab-root.MuiSpeedDial-fab.MuiFab-primary': {
    color: 'white',
    boxShadow: 'none',
  },
  '.MuiSpeedDialAction-fab': {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    maxWidth: '40px',
    minHeight: '40px',
    maxHeight: '40px',
  },
  '.MuiSvgIcon-root': {
    fontSize: '2.5rem',
  },
  '.MuiFab-root': {
    width: 'auto',
    height: '40px',
  },
  '#User-actions': {
    marginTop: '-40px',
  },
  '.MuiFab-primary:hover': {
    backgroundColor: 'initial',
  },
});

const StyledDialIcon = styled(AccountCircleIcon)<{ color: string }>((props) => ({
  color: props.color || 'rgb(52, 92, 68)',
  fontSize: '40px',
}));

const StyledCloseIcon = styled(CloseIcon)<{ showVm: boolean }>((props) => ({
  color: props.showVm ? 'rgb(52, 92, 68)' : 'white',
}));

type HeaderProps = {
  userLogo?: boolean,
  title?: string,
  color?: string,
  showVm?: boolean,
  marginBottom?: string,
} & RouteProps

const TopHeader = ({
  userLogo,
  showVm,
  history,
  location,
  title,
  color,
  marginBottom = '0',
}:HeaderProps) => {
  const [open, setOpen] = React.useState(false);
  const { vendingMachineInfo, stickerId } = useVendingMachine();
  const { signOut, loggedIn } = useUser();

  const backgroundColor = showVm ? 'white' : 'transparent';
  const shadow = showVm ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none';

  const actions = [
    {
      icon: <BiWorld
        size="25px"
        color={showVm ? 'rgb(52, 92, 68)' : 'rgb(255,147,0)'}
        onClick={() => history.push(`/languages${stickerId ? `?s=${stickerId}` : ''}`)}
      />,
      name: i18n.t('languages'),
    },
    {
      icon: <FaUserEdit
        size="25px"
        color={showVm ? 'rgb(52, 92, 68)' : 'rgb(255,147,0)'}
        onClick={() => history.push(`/profile${stickerId ? `?s=${stickerId}` : ''}`)}
      />,
      name: i18n.t('name'),
    },
    {
      icon: <FaKey
        size="25px"
        color={showVm ? 'rgb(52, 92, 68)' : 'rgb(255,147,0)'}
        onClick={() => history.push(`/password-change${stickerId ? `?s=${stickerId}` : ''}`)}
      />,
      name: i18n.t('password'),
    },
    {
      icon: <FiLogOut
        size="25px"
        color={showVm ? 'rgb(52, 92, 68)' : 'rgb(255,147,0)'}
        onClick={signOut}
      />,
      name: i18n.t('logout'),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (loggedIn) {
      setOpen(true);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    if (!loggedIn && location.pathname !== '/' && location.pathname !== '/register') {
      history.push('/');
    }
  }, [loggedIn]);

  return (
    <Header style={{ backgroundColor, boxShadow: shadow, marginBottom }}>
      <RowBackContainer>
        { showVm ? (
          <ContentContainer style={{ color: 'rgba(52,92,68,1)', zIndex: 5 }}>
            <HeaderName>{vendingMachineInfo?.name}</HeaderName>
            {vendingMachineInfo?.identificationNumber && (
            <InfoText>
              <b>ID</b>
              {' '}
              {vendingMachineInfo?.identificationNumber}
            </InfoText>
                )}
          </ContentContainer>
        ) : (
          <ContentContainer>
            <HeaderName
              style={{
                marginBottom: '0',
                color: title === 'initial_menu'
                  ? 'rgb(255, 255, 255)' : 'rgb(52, 92, 68)',
              }}
            >
              {i18n.t(title)}
            </HeaderName>
          </ContentContainer>
        )}
        { userLogo && (
          <ExtraContainer>
            {' '}
            {
              (loggedIn
                ? (
                  <div>
                    <StyledSpeedDial
                      ariaLabel="User"
                      icon={(
                        <SpeedDialIcon
                          style={{
                            minWidth: '40px',
                            maxWidth: '40px',
                            minHeight: '40px',
                            maxHeight: '40px',
                          }}
                          icon={<StyledDialIcon color={color} />}
                          openIcon={<StyledCloseIcon showVm={showVm} />}
                        />
                        )}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      open={open}
                      direction="down"
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={handleClose}
                        />
                      ))}
                    </StyledSpeedDial>
                  </div>
                )
                : (
                  <StyledSpeedDial
                    ariaLabel="User"
                    icon={<StyledDialIcon color={color} />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                    direction="down"
                  />
                )

              )
            }
            {' '}
          </ExtraContainer>
        )}
      </RowBackContainer>
    </Header>
  );
};

export default withRouter(TopHeader);

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import ReactPDF from '@react-pdf/renderer';
import { FormControl } from '@material-ui/core';
import { DocumentInfo, SessionInfo } from '../types/types';
import { getDocuments } from '../api/api';
import PdfBuilder from './PdfBuilder';
import i18n from '../i18n/i18n';
import BackgroundImage from '../components/BackgroundImage';
import LargeButton from '../components/LargeButton';
import Header from '../components/TopHeader';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

const Message = styled.h1`
  text-align: center;
  margin-top: 2.5em;
`;

const DocumentsContainer = styled.div`
  height: 60vh;
  overflow-y: scroll;
  align-self: center;
  width: 100%;
`;

const BuyButton = styled(LargeButton)`
  background-color: rgb(52, 92, 68);
  color: #ffffff;
  margin: 1em 0;
  font-size: 1.1em;
  justify-content: center;
  padding: 5px;
`;

const SessionDocumentsView = ({ location }) => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
  const sessionId = location.pathname.split('/')[3];

  const fetchDocuments = async (id) => {
    try {
      const response = await getDocuments(id);
      setSessionInfo(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDocuments(sessionId);
  }, []);

  return (
    <Root>
      <div style={{ flex: 1 }}>
          <Header userLogo showVm />
          <FormControl
            style={{
              margin: '0 2vh 2vh 2vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Message>{i18n.t('thanks_for_purchase')}</Message>
            <DocumentsContainer>
              {sessionInfo && sessionInfo.documents.map((documentInfo: DocumentInfo) => (
                <BuyButton onClick={async () => {
                  const pdfDocument = await ReactPDF.pdf(<PdfBuilder document={documentInfo} />).toBlob();
                  const url = window.URL.createObjectURL(pdfDocument);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', i18n.t('document'));
                  link.click();
                  link.remove();
                }}
                >
                  <DownloadIcon style={{ color: 'rgb(255, 255, 255)', margin: '0 10px 0 0' }} />
                  {documentInfo.type === 'CUSTOMER' ? i18n.t('payment_document') : i18n.t('invoice')}
                </BuyButton>
              ))}
            </DocumentsContainer>
          </FormControl>
      </div>
    </Root>
  );
};

export default SessionDocumentsView;
